<template>
    <q-dialog v-model="dialog" :position="position" class="rc__customSnackBar" transition-hide="fade" style="min-width: 500px;">
        <q-card flat bordered class="rc__roundCard" :style="color">
            <q-card-section class="row items-center no-wrap">
                <q-icon :name="icon" size="md" :style="icon_style" style="margin-right: 15px;"/>
            <div class="row items-center q-gutter-lg">
                <div class="rc_textAlert" :style="color_font">{{msje}}</div>
            </div>

            <q-space />

            <q-btn flat @click="close" :style="color_font" class="rc_textAlert" label="Cerrar"></q-btn>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import {ref, watchEffect} from 'vue'
export default {
    name:'CustomSnackbar',
    props: {
        text: String, 
        tipo: String,
        alert: Boolean
    },
    setup(props, context){
        const dialog = ref(false);
        const position = ref('bottom');

        let color = ref('');
        let icon = ref('');
        let icon_style = ref('');
        let color_font = ref('');

        const msje = ref(props.text);

        watchEffect(() => {
            let isActive = props.alert;
            if(isActive){
                if(props.tipo === 'success'){
                    isSuccess();
                }
                else if(props.tipo === 'failed'){
                    isFailed();
                }
            }
        })


        function isSuccess(){
            msje.value = props.text
            color.value = " min-width: 500px; border-radius: 25px; background: #E0FFF9";
            icon.value = "check_circle";
            icon_style.value = "margin-left: 20px; color: #00d1a8";
            color_font.value ="color: #00d1a8; text-transform: none";
            dialog.value = true;
        }
        function isFailed(){
            msje.value = props.text
            color.value = "min-width: 500px; border-radius: 25px; background: #E25A43";
            icon.value = "highlight_off";
            icon_style.value = "margin-left: 20px; color: white";
            color_font.value ="color: white; text-transform: none";
            dialog.value = true;
        }

        function close(){
            dialog.value = false;
            context.emit("changeState", dialog.value)
        }

        return {dialog, position, color, icon, icon_style, color_font, msje,close}
    }
}
</script>

<style lang="scss">
.rc__customSnackBar > .q-dialog__backdrop{
    background: rgba(0, 0, 0 ,0) !important;
}

.rc__customSnackBar> .fixed-bottom{
    right: 0;
    bottom: 60px !important;
    // TODO: revisar
    left: 0;
}

</style>
